/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * Openorigin API Documentation
 * OpenAPI spec version: 1.0.0
 */
import type { Arguments, Key, SWRConfiguration } from 'swr'
import useSwr from 'swr'
import type { SWRMutationConfiguration } from 'swr/mutation'
import useSWRMutation from 'swr/mutation'

import { apiClient } from '../base'
import { formDataMutator } from '../formDataMutator'
import type {
  AuthLogin200,
  AuthLoginBody,
  AuthRegister200,
  AuthRegisterBody,
  CommentsReactionsCreate200,
  CommentsReactionsDelete200,
  ModelCommentsCreate200,
  ModelCommentsCreateBody,
  ModelCommentsList200,
  ModelReportsCreateBody,
  ModelsCreate200,
  ModelsCreateBody,
  ModelsFavoriteAdd200,
  ModelsFavoriteRemove200,
  ModelsFavorites200,
  ModelsFavoritesParams,
  ModelsList200,
  ModelsListParams,
  ModelsMy200,
  ModelsMyParams,
  ModelsShow200,
  ModelsTotal200,
  ModelsUpdate200,
  ModelsUpdateBody,
  ModelTagsList200,
  PasswordForgot200,
  PasswordForgotBody,
  PasswordReset200,
  PasswordResetBody,
  PremiumSubscriptionSubscribeBody,
  Profile200,
  ProfileChangePassword200,
  ProfileChangePasswordBody,
  ProfileComments200,
  ProfileUpdate200,
  ProfileUpdateAccount200,
  ProfileUpdateAccountBody,
  ProfileUpdateBody,
  WidgetsCommunityStats200,
  WidgetsTeamMembers200,
  WidgetsTokenUtility200,
} from '../model'

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Auth: Login
 */
export const authLogin = (
  authLoginBody: AuthLoginBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<AuthLogin200>(
    {
      url: `/api/auth/login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authLoginBody,
    },
    options,
  )
}

export const getAuthLoginMutationFetcher = (options?: SecondParameter<typeof apiClient>) => {
  return (_: Key, { arg }: { arg: AuthLoginBody }): Promise<AuthLogin200> => {
    return authLogin(arg, options)
  }
}
export const getAuthLoginMutationKey = () => [`/api/auth/login`] as const

export type AuthLoginMutationResult = NonNullable<Awaited<ReturnType<typeof authLogin>>>
export type AuthLoginMutationError = unknown

/**
 * @summary Auth: Login
 */
export const useAuthLogin = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authLogin>>,
    TError,
    Key,
    AuthLoginBody,
    Awaited<ReturnType<typeof authLogin>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getAuthLoginMutationKey()
  const swrFn = getAuthLoginMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Auth: Register
 */
export const authRegister = (
  authRegisterBody: AuthRegisterBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<AuthRegister200>(
    {
      url: `/api/auth/register`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authRegisterBody,
    },
    options,
  )
}

export const getAuthRegisterMutationFetcher = (options?: SecondParameter<typeof apiClient>) => {
  return (_: Key, { arg }: { arg: AuthRegisterBody }): Promise<AuthRegister200> => {
    return authRegister(arg, options)
  }
}
export const getAuthRegisterMutationKey = () => [`/api/auth/register`] as const

export type AuthRegisterMutationResult = NonNullable<Awaited<ReturnType<typeof authRegister>>>
export type AuthRegisterMutationError = unknown

/**
 * @summary Auth: Register
 */
export const useAuthRegister = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authRegister>>,
    TError,
    Key,
    AuthRegisterBody,
    Awaited<ReturnType<typeof authRegister>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getAuthRegisterMutationKey()
  const swrFn = getAuthRegisterMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Auth: Logout
 */
export const authLogout = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<void>({ url: `/api/auth/logout`, method: 'POST' }, options)
}

export const getAuthLogoutMutationFetcher = (options?: SecondParameter<typeof apiClient>) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return authLogout(options)
  }
}
export const getAuthLogoutMutationKey = () => [`/api/auth/logout`] as const

export type AuthLogoutMutationResult = NonNullable<Awaited<ReturnType<typeof authLogout>>>
export type AuthLogoutMutationError = unknown

/**
 * @summary Auth: Logout
 */
export const useAuthLogout = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof authLogout>>,
    TError,
    Key,
    Arguments,
    Awaited<ReturnType<typeof authLogout>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getAuthLogoutMutationKey()
  const swrFn = getAuthLogoutMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Comments Reactions: Create
 */
export const commentsReactionsCreate = (
  commentId: number,
  reactionId: number,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<CommentsReactionsCreate200>(
    { url: `/api/comments/reactions/${commentId}/${reactionId}`, method: 'POST' },
    options,
  )
}

export const getCommentsReactionsCreateMutationFetcher = (
  commentId: number,
  reactionId: number,
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<CommentsReactionsCreate200> => {
    return commentsReactionsCreate(commentId, reactionId, options)
  }
}
export const getCommentsReactionsCreateMutationKey = (commentId: number, reactionId: number) =>
  [`/api/comments/reactions/${commentId}/${reactionId}`] as const

export type CommentsReactionsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof commentsReactionsCreate>>
>
export type CommentsReactionsCreateMutationError = unknown

/**
 * @summary Comments Reactions: Create
 */
export const useCommentsReactionsCreate = <TError = unknown>(
  commentId: number,
  reactionId: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof commentsReactionsCreate>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof commentsReactionsCreate>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCommentsReactionsCreateMutationKey(commentId, reactionId)
  const swrFn = getCommentsReactionsCreateMutationFetcher(commentId, reactionId, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Comments Reactions: Delete
 */
export const commentsReactionsDelete = (
  commentId: number,
  reactionId: number,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<CommentsReactionsDelete200>(
    { url: `/api/comments/reactions/${commentId}/${reactionId}`, method: 'DELETE' },
    options,
  )
}

export const getCommentsReactionsDeleteMutationFetcher = (
  commentId: number,
  reactionId: number,
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<CommentsReactionsDelete200> => {
    return commentsReactionsDelete(commentId, reactionId, options)
  }
}
export const getCommentsReactionsDeleteMutationKey = (commentId: number, reactionId: number) =>
  [`/api/comments/reactions/${commentId}/${reactionId}`] as const

export type CommentsReactionsDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof commentsReactionsDelete>>
>
export type CommentsReactionsDeleteMutationError = unknown

/**
 * @summary Comments Reactions: Delete
 */
export const useCommentsReactionsDelete = <TError = unknown>(
  commentId: number,
  reactionId: number,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof commentsReactionsDelete>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof commentsReactionsDelete>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getCommentsReactionsDeleteMutationKey(commentId, reactionId)
  const swrFn = getCommentsReactionsDeleteMutationFetcher(commentId, reactionId, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Model Comments: List
 */
export const modelCommentsList = (model: string, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelCommentsList200>(
    { url: `/api/models/${model}/comments`, method: 'GET' },
    options,
  )
}

export const getModelCommentsListKey = (model: string) => [`/api/models/${model}/comments`] as const

export type ModelCommentsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof modelCommentsList>>
>
export type ModelCommentsListQueryError = unknown

/**
 * @summary Model Comments: List
 */
export const useModelCommentsList = <TError = unknown>(
  model: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof modelCommentsList>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!model
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getModelCommentsListKey(model) : null))
  const swrFn = () => modelCommentsList(model, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Model Comments: Create
 */
export const modelCommentsCreate = (
  model: string,
  modelCommentsCreateBody: ModelCommentsCreateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ModelCommentsCreate200>(
    {
      url: `/api/models/${model}/comments`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: modelCommentsCreateBody,
    },
    options,
  )
}

export const getModelCommentsCreateMutationFetcher = (
  model: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, { arg }: { arg: ModelCommentsCreateBody }): Promise<ModelCommentsCreate200> => {
    return modelCommentsCreate(model, arg, options)
  }
}
export const getModelCommentsCreateMutationKey = (model: string) =>
  [`/api/models/${model}/comments`] as const

export type ModelCommentsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof modelCommentsCreate>>
>
export type ModelCommentsCreateMutationError = unknown

/**
 * @summary Model Comments: Create
 */
export const useModelCommentsCreate = <TError = unknown>(
  model: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof modelCommentsCreate>>,
      TError,
      Key,
      ModelCommentsCreateBody,
      Awaited<ReturnType<typeof modelCommentsCreate>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getModelCommentsCreateMutationKey(model)
  const swrFn = getModelCommentsCreateMutationFetcher(model, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Model Comments: Delete
 */
export const modelCommentsDelete = (
  model: string,
  comment: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<void>(
    { url: `/api/models/${model}/comments/${comment}`, method: 'DELETE' },
    options,
  )
}

export const getModelCommentsDeleteMutationFetcher = (
  model: string,
  comment: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return modelCommentsDelete(model, comment, options)
  }
}
export const getModelCommentsDeleteMutationKey = (model: string, comment: string) =>
  [`/api/models/${model}/comments/${comment}`] as const

export type ModelCommentsDeleteMutationResult = NonNullable<
  Awaited<ReturnType<typeof modelCommentsDelete>>
>
export type ModelCommentsDeleteMutationError = unknown

/**
 * @summary Model Comments: Delete
 */
export const useModelCommentsDelete = <TError = unknown>(
  model: string,
  comment: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof modelCommentsDelete>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof modelCommentsDelete>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getModelCommentsDeleteMutationKey(model, comment)
  const swrFn = getModelCommentsDeleteMutationFetcher(model, comment, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Model Reports: Create
 */
export const modelReportsCreate = (
  model: string,
  modelReportsCreateBody: ModelReportsCreateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<void>(
    {
      url: `/api/models/${model}/report`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: modelReportsCreateBody,
    },
    options,
  )
}

export const getModelReportsCreateMutationFetcher = (
  model: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, { arg }: { arg: ModelReportsCreateBody }): Promise<void> => {
    return modelReportsCreate(model, arg, options)
  }
}
export const getModelReportsCreateMutationKey = (model: string) =>
  [`/api/models/${model}/report`] as const

export type ModelReportsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof modelReportsCreate>>
>
export type ModelReportsCreateMutationError = unknown

/**
 * @summary Model Reports: Create
 */
export const useModelReportsCreate = <TError = unknown>(
  model: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof modelReportsCreate>>,
      TError,
      Key,
      ModelReportsCreateBody,
      Awaited<ReturnType<typeof modelReportsCreate>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getModelReportsCreateMutationKey(model)
  const swrFn = getModelReportsCreateMutationFetcher(model, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Model Tags: List
 */
export const modelTagsList = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelTagsList200>({ url: `/api/model-tags`, method: 'GET' }, options)
}

export const getModelTagsListKey = () => [`/api/model-tags`] as const

export type ModelTagsListQueryResult = NonNullable<Awaited<ReturnType<typeof modelTagsList>>>
export type ModelTagsListQueryError = unknown

/**
 * @summary Model Tags: List
 */
export const useModelTagsList = <TError = unknown>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof modelTagsList>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getModelTagsListKey() : null))
  const swrFn = () => modelTagsList(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: My
 */
export const modelsMy = (params?: ModelsMyParams, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelsMy200>({ url: `/api/models/my`, method: 'GET', params }, options)
}

export const getModelsMyKey = (params?: ModelsMyParams) =>
  [`/api/models/my`, ...(params ? [params] : [])] as const

export type ModelsMyQueryResult = NonNullable<Awaited<ReturnType<typeof modelsMy>>>
export type ModelsMyQueryError = unknown

/**
 * @summary Models: My
 */
export const useModelsMy = <TError = unknown>(
  params?: ModelsMyParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof modelsMy>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getModelsMyKey(params) : null))
  const swrFn = () => modelsMy(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: Favorite Add
 */
export const modelsFavoriteAdd = (model: string, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelsFavoriteAdd200>(
    { url: `/api/models/${model}/favorite`, method: 'POST' },
    options,
  )
}

export const getModelsFavoriteAddMutationFetcher = (
  model: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<ModelsFavoriteAdd200> => {
    return modelsFavoriteAdd(model, options)
  }
}
export const getModelsFavoriteAddMutationKey = (model: string) =>
  [`/api/models/${model}/favorite`] as const

export type ModelsFavoriteAddMutationResult = NonNullable<
  Awaited<ReturnType<typeof modelsFavoriteAdd>>
>
export type ModelsFavoriteAddMutationError = unknown

/**
 * @summary Models: Favorite Add
 */
export const useModelsFavoriteAdd = <TError = unknown>(
  model: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof modelsFavoriteAdd>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof modelsFavoriteAdd>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getModelsFavoriteAddMutationKey(model)
  const swrFn = getModelsFavoriteAddMutationFetcher(model, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: Favorite Remove
 */
export const modelsFavoriteRemove = (
  model: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ModelsFavoriteRemove200>(
    { url: `/api/models/${model}/favorite`, method: 'DELETE' },
    options,
  )
}

export const getModelsFavoriteRemoveMutationFetcher = (
  model: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<ModelsFavoriteRemove200> => {
    return modelsFavoriteRemove(model, options)
  }
}
export const getModelsFavoriteRemoveMutationKey = (model: string) =>
  [`/api/models/${model}/favorite`] as const

export type ModelsFavoriteRemoveMutationResult = NonNullable<
  Awaited<ReturnType<typeof modelsFavoriteRemove>>
>
export type ModelsFavoriteRemoveMutationError = unknown

/**
 * @summary Models: Favorite Remove
 */
export const useModelsFavoriteRemove = <TError = unknown>(
  model: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof modelsFavoriteRemove>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof modelsFavoriteRemove>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getModelsFavoriteRemoveMutationKey(model)
  const swrFn = getModelsFavoriteRemoveMutationFetcher(model, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: Favorites
 */
export const modelsFavorites = (
  params?: ModelsFavoritesParams,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ModelsFavorites200>(
    { url: `/api/models/favorites`, method: 'GET', params },
    options,
  )
}

export const getModelsFavoritesKey = (params?: ModelsFavoritesParams) =>
  [`/api/models/favorites`, ...(params ? [params] : [])] as const

export type ModelsFavoritesQueryResult = NonNullable<Awaited<ReturnType<typeof modelsFavorites>>>
export type ModelsFavoritesQueryError = unknown

/**
 * @summary Models: Favorites
 */
export const useModelsFavorites = <TError = unknown>(
  params?: ModelsFavoritesParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof modelsFavorites>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getModelsFavoritesKey(params) : null))
  const swrFn = () => modelsFavorites(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: Update
 */
export const modelsUpdate = (
  model: string,
  modelsUpdateBody: ModelsUpdateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  const formData = formDataMutator(modelsUpdateBody)
  return apiClient<ModelsUpdate200>(
    {
      url: `/api/models/${model}`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  )
}

export const getModelsUpdateMutationFetcher = (
  model: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, { arg }: { arg: ModelsUpdateBody }): Promise<ModelsUpdate200> => {
    return modelsUpdate(model, arg, options)
  }
}
export const getModelsUpdateMutationKey = (model: string) => [`/api/models/${model}`] as const

export type ModelsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof modelsUpdate>>>
export type ModelsUpdateMutationError = unknown

/**
 * @summary Models: Update
 */
export const useModelsUpdate = <TError = unknown>(
  model: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof modelsUpdate>>,
      TError,
      Key,
      ModelsUpdateBody,
      Awaited<ReturnType<typeof modelsUpdate>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getModelsUpdateMutationKey(model)
  const swrFn = getModelsUpdateMutationFetcher(model, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: Total
 */
export const modelsTotal = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelsTotal200>({ url: `/api/models/total`, method: 'GET' }, options)
}

export const getModelsTotalKey = () => [`/api/models/total`] as const

export type ModelsTotalQueryResult = NonNullable<Awaited<ReturnType<typeof modelsTotal>>>
export type ModelsTotalQueryError = unknown

/**
 * @summary Models: Total
 */
export const useModelsTotal = <TError = unknown>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof modelsTotal>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getModelsTotalKey() : null))
  const swrFn = () => modelsTotal(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: List
 */
export const modelsList = (
  params?: ModelsListParams,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ModelsList200>({ url: `/api/models`, method: 'GET', params }, options)
}

export const getModelsListKey = (params?: ModelsListParams) =>
  [`/api/models`, ...(params ? [params] : [])] as const

export type ModelsListQueryResult = NonNullable<Awaited<ReturnType<typeof modelsList>>>
export type ModelsListQueryError = unknown

/**
 * @summary Models: List
 */
export const useModelsList = <TError = unknown>(
  params?: ModelsListParams,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof modelsList>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getModelsListKey(params) : null))
  const swrFn = () => modelsList(params, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: Create
 */
export const modelsCreate = (
  modelsCreateBody: ModelsCreateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  const formData = formDataMutator(modelsCreateBody)
  return apiClient<ModelsCreate200>(
    {
      url: `/api/models`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  )
}

export const getModelsCreateMutationFetcher = (options?: SecondParameter<typeof apiClient>) => {
  return (_: Key, { arg }: { arg: ModelsCreateBody }): Promise<ModelsCreate200> => {
    return modelsCreate(arg, options)
  }
}
export const getModelsCreateMutationKey = () => [`/api/models`] as const

export type ModelsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof modelsCreate>>>
export type ModelsCreateMutationError = unknown

/**
 * @summary Models: Create
 */
export const useModelsCreate = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof modelsCreate>>,
    TError,
    Key,
    ModelsCreateBody,
    Awaited<ReturnType<typeof modelsCreate>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getModelsCreateMutationKey()
  const swrFn = getModelsCreateMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: Show
 */
export const modelsShow = (id: string, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ModelsShow200>({ url: `/api/models/${id}`, method: 'GET' }, options)
}

export const getModelsShowKey = (id: string) => [`/api/models/${id}`] as const

export type ModelsShowQueryResult = NonNullable<Awaited<ReturnType<typeof modelsShow>>>
export type ModelsShowQueryError = unknown

/**
 * @summary Models: Show
 */
export const useModelsShow = <TError = unknown>(
  id: string,
  options?: {
    swr?: SWRConfiguration<Awaited<ReturnType<typeof modelsShow>>, TError> & {
      swrKey?: Key
      enabled?: boolean
    }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false && !!id
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getModelsShowKey(id) : null))
  const swrFn = () => modelsShow(id, requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Models: Delete
 */
export const modelsDelete = (id: string, options?: SecondParameter<typeof apiClient>) => {
  return apiClient<void>({ url: `/api/models/${id}`, method: 'DELETE' }, options)
}

export const getModelsDeleteMutationFetcher = (
  id: string,
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return modelsDelete(id, options)
  }
}
export const getModelsDeleteMutationKey = (id: string) => [`/api/models/${id}`] as const

export type ModelsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof modelsDelete>>>
export type ModelsDeleteMutationError = unknown

/**
 * @summary Models: Delete
 */
export const useModelsDelete = <TError = unknown>(
  id: string,
  options?: {
    swr?: SWRMutationConfiguration<
      Awaited<ReturnType<typeof modelsDelete>>,
      TError,
      Key,
      Arguments,
      Awaited<ReturnType<typeof modelsDelete>>
    > & { swrKey?: string }
    request?: SecondParameter<typeof apiClient>
  },
) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getModelsDeleteMutationKey(id)
  const swrFn = getModelsDeleteMutationFetcher(id, requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Password: Forgot
 */
export const passwordForgot = (
  passwordForgotBody: PasswordForgotBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<PasswordForgot200>(
    {
      url: `/api/auth/forgot-password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: passwordForgotBody,
    },
    options,
  )
}

export const getPasswordForgotMutationFetcher = (options?: SecondParameter<typeof apiClient>) => {
  return (_: Key, { arg }: { arg: PasswordForgotBody }): Promise<PasswordForgot200> => {
    return passwordForgot(arg, options)
  }
}
export const getPasswordForgotMutationKey = () => [`/api/auth/forgot-password`] as const

export type PasswordForgotMutationResult = NonNullable<Awaited<ReturnType<typeof passwordForgot>>>
export type PasswordForgotMutationError = unknown

/**
 * @summary Password: Forgot
 */
export const usePasswordForgot = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof passwordForgot>>,
    TError,
    Key,
    PasswordForgotBody,
    Awaited<ReturnType<typeof passwordForgot>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getPasswordForgotMutationKey()
  const swrFn = getPasswordForgotMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Password: Reset
 */
export const passwordReset = (
  passwordResetBody: PasswordResetBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<PasswordReset200>(
    {
      url: `/api/auth/reset-password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: passwordResetBody,
    },
    options,
  )
}

export const getPasswordResetMutationFetcher = (options?: SecondParameter<typeof apiClient>) => {
  return (_: Key, { arg }: { arg: PasswordResetBody }): Promise<PasswordReset200> => {
    return passwordReset(arg, options)
  }
}
export const getPasswordResetMutationKey = () => [`/api/auth/reset-password`] as const

export type PasswordResetMutationResult = NonNullable<Awaited<ReturnType<typeof passwordReset>>>
export type PasswordResetMutationError = unknown

/**
 * @summary Password: Reset
 */
export const usePasswordReset = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof passwordReset>>,
    TError,
    Key,
    PasswordResetBody,
    Awaited<ReturnType<typeof passwordReset>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getPasswordResetMutationKey()
  const swrFn = getPasswordResetMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Premium Subscription: Subscribe
 */
export const premiumSubscriptionSubscribe = (
  premiumSubscriptionSubscribeBody: PremiumSubscriptionSubscribeBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<void>(
    {
      url: `/api/premium-subscription`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: premiumSubscriptionSubscribeBody,
    },
    options,
  )
}

export const getPremiumSubscriptionSubscribeMutationFetcher = (
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, { arg }: { arg: PremiumSubscriptionSubscribeBody }): Promise<void> => {
    return premiumSubscriptionSubscribe(arg, options)
  }
}
export const getPremiumSubscriptionSubscribeMutationKey = () =>
  [`/api/premium-subscription`] as const

export type PremiumSubscriptionSubscribeMutationResult = NonNullable<
  Awaited<ReturnType<typeof premiumSubscriptionSubscribe>>
>
export type PremiumSubscriptionSubscribeMutationError = unknown

/**
 * @summary Premium Subscription: Subscribe
 */
export const usePremiumSubscriptionSubscribe = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof premiumSubscriptionSubscribe>>,
    TError,
    Key,
    PremiumSubscriptionSubscribeBody,
    Awaited<ReturnType<typeof premiumSubscriptionSubscribe>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getPremiumSubscriptionSubscribeMutationKey()
  const swrFn = getPremiumSubscriptionSubscribeMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Profile
 */
export const profile = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<Profile200>({ url: `/api/profile`, method: 'GET' }, options)
}

export const getProfileKey = () => [`/api/profile`] as const

export type ProfileQueryResult = NonNullable<Awaited<ReturnType<typeof profile>>>
export type ProfileQueryError = unknown

/**
 * @summary Profile
 */
export const useProfile = <TError = unknown>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof profile>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getProfileKey() : null))
  const swrFn = () => profile(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Profile: Update
 */
export const profileUpdate = (
  profileUpdateBody: ProfileUpdateBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  const formData = formDataMutator(profileUpdateBody)
  return apiClient<ProfileUpdate200>(
    {
      url: `/api/profile`,
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    },
    options,
  )
}

export const getProfileUpdateMutationFetcher = (options?: SecondParameter<typeof apiClient>) => {
  return (_: Key, { arg }: { arg: ProfileUpdateBody }): Promise<ProfileUpdate200> => {
    return profileUpdate(arg, options)
  }
}
export const getProfileUpdateMutationKey = () => [`/api/profile`] as const

export type ProfileUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof profileUpdate>>>
export type ProfileUpdateMutationError = unknown

/**
 * @summary Profile: Update
 */
export const useProfileUpdate = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof profileUpdate>>,
    TError,
    Key,
    ProfileUpdateBody,
    Awaited<ReturnType<typeof profileUpdate>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getProfileUpdateMutationKey()
  const swrFn = getProfileUpdateMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Profile: Delete
 */
export const profileDelete = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<void>({ url: `/api/profile`, method: 'DELETE' }, options)
}

export const getProfileDeleteMutationFetcher = (options?: SecondParameter<typeof apiClient>) => {
  return (_: Key, __: { arg: Arguments }): Promise<void> => {
    return profileDelete(options)
  }
}
export const getProfileDeleteMutationKey = () => [`/api/profile`] as const

export type ProfileDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof profileDelete>>>
export type ProfileDeleteMutationError = unknown

/**
 * @summary Profile: Delete
 */
export const useProfileDelete = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof profileDelete>>,
    TError,
    Key,
    Arguments,
    Awaited<ReturnType<typeof profileDelete>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getProfileDeleteMutationKey()
  const swrFn = getProfileDeleteMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Profile: Update Account
 */
export const profileUpdateAccount = (
  profileUpdateAccountBody: ProfileUpdateAccountBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ProfileUpdateAccount200>(
    {
      url: `/api/profile/account`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: profileUpdateAccountBody,
    },
    options,
  )
}

export const getProfileUpdateAccountMutationFetcher = (
  options?: SecondParameter<typeof apiClient>,
) => {
  return (_: Key, { arg }: { arg: ProfileUpdateAccountBody }): Promise<ProfileUpdateAccount200> => {
    return profileUpdateAccount(arg, options)
  }
}
export const getProfileUpdateAccountMutationKey = () => [`/api/profile/account`] as const

export type ProfileUpdateAccountMutationResult = NonNullable<
  Awaited<ReturnType<typeof profileUpdateAccount>>
>
export type ProfileUpdateAccountMutationError = unknown

/**
 * @summary Profile: Update Account
 */
export const useProfileUpdateAccount = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof profileUpdateAccount>>,
    TError,
    Key,
    ProfileUpdateAccountBody,
    Awaited<ReturnType<typeof profileUpdateAccount>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getProfileUpdateAccountMutationKey()
  const swrFn = getProfileUpdateAccountMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Profile: Change Password
 */
export const profileChangePassword = (
  profileChangePasswordBody: ProfileChangePasswordBody,
  options?: SecondParameter<typeof apiClient>,
) => {
  return apiClient<ProfileChangePassword200>(
    {
      url: `/api/profile/password`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: profileChangePasswordBody,
    },
    options,
  )
}

export const getProfileChangePasswordMutationFetcher = (
  options?: SecondParameter<typeof apiClient>,
) => {
  return (
    _: Key,
    { arg }: { arg: ProfileChangePasswordBody },
  ): Promise<ProfileChangePassword200> => {
    return profileChangePassword(arg, options)
  }
}
export const getProfileChangePasswordMutationKey = () => [`/api/profile/password`] as const

export type ProfileChangePasswordMutationResult = NonNullable<
  Awaited<ReturnType<typeof profileChangePassword>>
>
export type ProfileChangePasswordMutationError = unknown

/**
 * @summary Profile: Change Password
 */
export const useProfileChangePassword = <TError = unknown>(options?: {
  swr?: SWRMutationConfiguration<
    Awaited<ReturnType<typeof profileChangePassword>>,
    TError,
    Key,
    ProfileChangePasswordBody,
    Awaited<ReturnType<typeof profileChangePassword>>
  > & { swrKey?: string }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const swrKey = swrOptions?.swrKey ?? getProfileChangePasswordMutationKey()
  const swrFn = getProfileChangePasswordMutationFetcher(requestOptions)

  const query = useSWRMutation(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Profile: Comments
 */
export const profileComments = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<ProfileComments200>({ url: `/api/profile/comments`, method: 'GET' }, options)
}

export const getProfileCommentsKey = () => [`/api/profile/comments`] as const

export type ProfileCommentsQueryResult = NonNullable<Awaited<ReturnType<typeof profileComments>>>
export type ProfileCommentsQueryError = unknown

/**
 * @summary Profile: Comments
 */
export const useProfileComments = <TError = unknown>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof profileComments>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getProfileCommentsKey() : null))
  const swrFn = () => profileComments(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Widgets: Community Stats
 */
export const widgetsCommunityStats = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<WidgetsCommunityStats200>(
    { url: `/api/widgets/community-stats`, method: 'GET' },
    options,
  )
}

export const getWidgetsCommunityStatsKey = () => [`/api/widgets/community-stats`] as const

export type WidgetsCommunityStatsQueryResult = NonNullable<
  Awaited<ReturnType<typeof widgetsCommunityStats>>
>
export type WidgetsCommunityStatsQueryError = unknown

/**
 * @summary Widgets: Community Stats
 */
export const useWidgetsCommunityStats = <TError = unknown>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof widgetsCommunityStats>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getWidgetsCommunityStatsKey() : null))
  const swrFn = () => widgetsCommunityStats(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Widgets: Team Members
 */
export const widgetsTeamMembers = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<WidgetsTeamMembers200>(
    { url: `/api/widgets/team-members`, method: 'GET' },
    options,
  )
}

export const getWidgetsTeamMembersKey = () => [`/api/widgets/team-members`] as const

export type WidgetsTeamMembersQueryResult = NonNullable<
  Awaited<ReturnType<typeof widgetsTeamMembers>>
>
export type WidgetsTeamMembersQueryError = unknown

/**
 * @summary Widgets: Team Members
 */
export const useWidgetsTeamMembers = <TError = unknown>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof widgetsTeamMembers>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getWidgetsTeamMembersKey() : null))
  const swrFn = () => widgetsTeamMembers(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}

/**
 * @summary Widgets: Token Utility
 */
export const widgetsTokenUtility = (options?: SecondParameter<typeof apiClient>) => {
  return apiClient<WidgetsTokenUtility200>(
    { url: `/api/widgets/token-utilities`, method: 'GET' },
    options,
  )
}

export const getWidgetsTokenUtilityKey = () => [`/api/widgets/token-utilities`] as const

export type WidgetsTokenUtilityQueryResult = NonNullable<
  Awaited<ReturnType<typeof widgetsTokenUtility>>
>
export type WidgetsTokenUtilityQueryError = unknown

/**
 * @summary Widgets: Token Utility
 */
export const useWidgetsTokenUtility = <TError = unknown>(options?: {
  swr?: SWRConfiguration<Awaited<ReturnType<typeof widgetsTokenUtility>>, TError> & {
    swrKey?: Key
    enabled?: boolean
  }
  request?: SecondParameter<typeof apiClient>
}) => {
  const { swr: swrOptions, request: requestOptions } = options ?? {}

  const isEnabled = swrOptions?.enabled !== false
  const swrKey = swrOptions?.swrKey ?? (() => (isEnabled ? getWidgetsTokenUtilityKey() : null))
  const swrFn = () => widgetsTokenUtility(requestOptions)

  const query = useSwr<Awaited<ReturnType<typeof swrFn>>, TError>(swrKey, swrFn, {
    ...swrOptions,
  })

  return {
    swrKey,
    ...query,
  }
}
